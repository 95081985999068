import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql, useQuery, Link } from "gatsby"
import Img from "gatsby-image"


import HeaderMinimal from "../../../components/header/HeaderMinimal"
import MainMenu from "../../../components/header/Menu"
import Footer from "../../../components/footer/Footer"

import Subheader from "../../../components/major-pieces/subheader/Subheader"
import SubMenu from "../../../components/major-pieces/subheader/SubMenu"
import PhotoAlbums from "../../../components/photo-gallery/PhotoAlbums"


import "../../../styles/global.css"
import "../../../styles/global_media.css"
import "../../../styles/photo-gallery.css"

import { Router, Route } from "@reach/router"


function PhotoGallery () {
	
	
	const query = graphql`
	      query {
			  allDirectory(filter: {relativePath: {glob: "photo-gallery/*"}}, sort: {order: DESC, fields: modifiedTime}) {
			    edges {
			      node {
			        id
			        name
			        relativePath
			      }
			    }
			  }
			  allFile(sort: {fields: modifiedTime, order: DESC}, filter: {relativePath: {glob: "photo-gallery/*/*"}}) {
			    edges {
			      node {
			        id
			        name
			        relativeDirectory
			        modifiedTime
			        childImageSharp {
	                fluid(maxWidth: 400) {
	                  ...GatsbyImageSharpFluid

	                }
	              }
			      }

			    }
			  }
			}
	`


	let data = useStaticQuery(query)
	return(
		<PhotoAlbums data={data}/>
	)
}

export default PhotoGallery 