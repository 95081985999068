import React, { Component } from "react"
import { Link } from "gatsby"
import HeaderMinimal from "../../components/header/HeaderMinimal"
import MainMenu from "../../components/header/Menu"
import HomeBody from "../../components/home/HomeBody"
import Footer from "../../components/footer/Footer"
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import "../../styles/global.css"
import "../../styles/global_media.css"
import "../../styles/donate.css"
import ReactModal from "react-modal"
import Img from "gatsby-image"
import iconImage from "../../../static/images/donate/icon.jpg" // Tell Webpack this JS file uses this image
import headerImage from "../../../static/images/donate/header.png" // Tell Webpack this JS file uses this image

class PhotoAlbums extends Component {
	constructor(props){
		super(props)
	}
	
	render() {


		let data = this.props.data;


		let albums = data.allDirectory.edges.map((item, key) => {
			let cover = null;
			cover = data.allFile.edges.find(element => ((element.node.name=='cover') && (item.node.relativePath === element.node.relativeDirectory)))

			if (typeof cover === 'undefined'){
				cover = data.allFile.edges.find(element => (item.node.relativePath === element.node.relativeDirectory))
				console.log(cover)
				cover= cover
			}


			return (
				{	
					"key":key,
					"name":item.node.name, 
					"path":item.node.relativePath,
					"cover": cover.node.childImageSharp
				}
			)
		})
	
	function getAlbumJSX(album, key) {
		console.log(album)
		const to = "/about-us/photo-gallery/album/"+album.name

		return (
			<Link to={to}  key={key}>
				
				<div className="album">
					<Img
				           		className="album-cover"
				           		fluid={album.cover.fluid}
				               	alt="" />
				    <div className="album-label">{album.name}</div>
				</div>
			</Link>
		)

	}

	let albumJSX = albums.map((album,key) => getAlbumJSX(album,key))


	return(
		<div className="site body">

			<HeaderMinimal />
			<MainMenu metadata="sub-page"/>
			<div className="line-across menu-line-across"></div>
			<h1 className="album-title">St. John's Photo Gallery</h1>

			<main className="site-content ministries-colors ministries">
					{albumJSX}
			</main>
			<Footer />		
		</div>
		)
	}

}
export default PhotoAlbums 